import './App.css';
import { useState } from 'react';
import Button from '@mui/material/Button';
import SignIn from './Form';
function App() {

  const [count, setCount] = useState(1);

  const incrementCounter = () => {
    setCount((prev) => prev * 2);
  }
  const decrementCounter = () => {
    setCount((prev) => prev - 1);
  }

  return (

    <div className="App">
      <SignIn />
      <header className="App-header">
        <title>{count}</title>
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        
          {count}
        
        <Button type="button" variant="contained" onClick={incrementCounter}>Increment {count}</Button>
        <Button type="button" onClick={decrementCounter}>Decrement {count}</Button>
    
      </header>
    </div>
  );
}

export default App;
